body {
  background-color: $bg01;
  font-size: rem(18px);
  margin: 0;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}

::selection {
  background-color: #43c155;
  color: #ffffff;
}

html {
  font-family: "Varela Round", sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h6 {
  margin: 0 0 0.6em;
  line-height: 1;
}
