.indicators {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
  gap: 15px;
}
.bluetooth-icon,
.wifi-icon {
  display: inline;
}
