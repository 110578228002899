.menuPage {
    display: flex;
}
.menuContainer {
    background-color: $sidebar-bg;
    width: 200px;
    height: 88vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.displayContainer {
    margin-top: 50px;
    width: 100%;
    text-align: center;
}
