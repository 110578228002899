.wifiList{
    border: 1px solid black;
    width: 200px;
    height: 150px;
    margin: auto;
    margin-top: 20px;
    overflow: auto;
}
.wifiBtn{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 10px;
    width: 150px;
    text-align: center;
}
.wifiInputContainer{
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;
    margin-top: 10px;
}
input{
    text-align: center;
}