
.device{
    border: 2px solid black;
    margin: auto;
    text-align: center;
    width: 50%;
    padding: 20px 0;
    margin-top: 50px;
    
}
.connect_button{
    width: 30%;
}