.login{
    border: 2px solid black;
    margin: auto;
    text-align: center;
    width: 50%;
    padding: 20px 0;
    margin-top: 50px;
}
.login_input{
    display: block;
    margin: 10px auto;
    height: 20px;
    width: 30%;
}
.login_button{
    margin: 10px 0;
    width: 20%;
}