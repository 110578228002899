.header {
  width: 100%;
  min-height: 100px;
  background-color: $header-bg;
  padding: 20px 30px;
}
.header--connect {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.main {
  margin: auto;
  padding-top: 50px;
  width: 100%;
  height: 500px;
}
@media (max-width:#{560px}) {
  .header--connect {
    justify-content: center;
  }
}
